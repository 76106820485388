import {lazy, Suspense} from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Pages
const HomePage = lazy(() => import('../pages/HomePage'));
const Users = lazy(() => import('../pages/Users'));
const Publishers = lazy(() => import('../pages/Publishers'));
const Blockeds = lazy(() => import('../pages/Blockeds'));
const Blocked = lazy(() => import('../pages/Blocked'));
const Posts = lazy(() => import('../pages/Posts'));
const PackageManagement = lazy(() => import('../pages/PackageManagement'));
const AdminManagement = lazy(() => import('../pages/AdminManagement'));
const FixedPages = lazy(() => import('../pages/FixedPages'));
const SiteSettings = lazy(() => import('../pages/SiteSettings'));
const PublisherStatistics = lazy(() => import('../pages/PublisherStatistics'));
const Gain = lazy(() => import('../pages/Gain'));
const Payments = lazy(() => import('../pages/Payments'));
const Payouts = lazy(() => import('../pages/Payouts'));
const Support = lazy(() => import('../pages/Support'));
const Login = lazy(() => import('../pages/Login'));
const Loading = lazy(() => import('../pages/Loading'));

const publicPages = [
    {path: "/login", element: <Login/>}
]

const privatePages = [
    {path: '/', element: <HomePage/>},
    {path: '/users', element: <Users/>},
    {path: '/posts', element: <Posts/>},
    {path: '/gain', element: <Gain/>},
    {path: '/payments', element: <Payments/>},
    {path: '/payouts', element: <Payouts/>},
    {path: '/package-management', element: <PackageManagement/>},
    {path: '/admin-management', element: <AdminManagement/>},
    {path: '/support', element: <Support/>},
    {path: '/publisher-statistics', element: <PublisherStatistics/>},
    {path: '/publisher-statistics/:id', element: <PublisherStatistics/>},
    {path: '/fixed-pages', element: <FixedPages/>},
    {path: '/site-settings', element: <SiteSettings/>},
    {path: '/publishers', element: <Publishers/>},
    {path: '/blockeds', element: <Blockeds/>},
    {path: '/blocked', element: <Blocked/>},
]

const AppRouter = () => {
    const [cookies] = useCookies(['token']);
    const PrivateRouterController = () => {
        return cookies.token ? <Outlet/> : <Navigate to='/login' replace />
    }
  return (
    <BrowserRouter>
        <Routes>
            {
                publicPages.map((page, index) => {
                    return <Route key={index} path={page.path} element={
                        <Suspense fallback={<Loading/>}>
                            {page.element}
                        </Suspense>
                    } />
                })
            }
            {
                privatePages.map((page, index) => {
                    return (
                        <Route
                        key={index}
                        path={page.path}
                        element={<PrivateRouterController/>}
                        >
                          <Route path="" element={
                             <Suspense fallback={<Loading />}>
                               {page.element} 
                             </Suspense>
                          }/>
                        </Route>
                      )
                })
            }
        </Routes>
    </BrowserRouter>
  )
}

export default AppRouter