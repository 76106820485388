import React, {createContext} from 'react'

export const AppContext = createContext();

const AppContextProvider = ({children}) => {
    const baseUrl = 'https://squid-app-ccite.ondigitalocean.app';
  return (
    <AppContext.Provider value={{baseUrl}}>
        {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider;